// ToastContext.js
import React, { createContext, useContext, useState, useCallback } from "react";

// Creamos un contexto para el Toast
const ToastContext = createContext();

// ToastProvider: encapsula la lógica y renderiza los Toasts en una posición fija
export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  // Función helper para mostrar un toast
  const showToast = useCallback((message, type = "info", duration = 3000) => {
    const id = Date.now(); // id único basado en el timestamp
    setToasts((prev) => [...prev, { id, message, type, duration }]);

    // Remueve el toast después de 'duration' ms
    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, duration);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {/* Contenedor de Toasts en la esquina inferior derecha */}
      <div className="fixed bottom-5 right-5 flex flex-col gap-2 z-50">
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

// Hook para usar el contexto en otros componentes
export const useToast = () => useContext(ToastContext);

// Componente Toast con animación
const Toast = ({ id, message, type, duration }) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    // Inicia la animación de aparición (fade in)
    setVisible(true);
    // Inicia la animación de salida (fade out) 300ms antes de eliminarlo
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration - 300);
    return () => clearTimeout(timer);
  }, [duration]);

  // Clases según el tipo del toast
  const typeClasses = {
    success: "bg-green-500 border-green-700",
    error: "bg-red-500 border-red-700",
    info: "bg-blue-500 border-blue-700",
    warning: "bg-yellow-500 border-yellow-700",
  };

  // Clases de transición para animar el toast
  const transitionClasses = visible
    ? "opacity-100 translate-y-0"
    : "opacity-0 translate-y-2";

  return (
    <div
      className={`max-w-sm w-full border-l-4 p-4 text-white shadow-lg rounded transition-all duration-300 ${transitionClasses} ${
        typeClasses[type] || typeClasses.info
      }`}
    >
      <p className="text-sm">{message}</p>
    </div>
  );
};

export default ToastProvider;