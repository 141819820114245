import axiosInstance from "./axiosConfig";

export const sendResetPasswordEmail = async ({ email }) => {
    try {
        return await axiosInstance.get(`v1/users/recover-password/${email}/`);
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async ({ password, email, token }) => {
    try {
        return await axiosInstance.put(`v1/users/recover-password/${email}/`, {
        password,
        token,
        });
    } catch (error) {
        throw { error, message: error.response.data.error };
    }
};