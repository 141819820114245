import { useState } from "react";
import { sendResetPasswordEmail } from "../api/reset-password";
import { useToast } from "../context/ToastContext";
import Loader from "./Loader";

const RecoverPasswordModal = ({ showModal, setShowModal }) => {
    const [email, setEmail] = useState("");
    const [sendingData, setSendingData] = useState(false);
    const { showToast } = useToast();

    const handleRecoverPassword = async () => {
    if (!email.trim()) {
        showToast("Debes introducir un correo electrónico.", "error", 3000);
        return;
    }
    setSendingData(true);

    try {
        await sendResetPasswordEmail({ email });
        showToast("Correo de recuperación enviado.", "success", 3000);
    } catch (error) {
        console.error(error);
        showToast(
        error?.response?.data?.error || "Error al enviar correo",
        "error",
        3000
        );
    } finally {
        setSendingData(false);
    }
};

const handleCloseModal = () => {
    setEmail("");
    setShowModal(false);
};

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center transition-opacity duration-300 ${
        showModal
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="relative p-4 w-full max-w-md">
        <div
          className={`relative bg-white rounded-lg shadow-sm dark:bg-gray-700 transform transition-all duration-300 ${
            showModal ? "scale-100" : "scale-95"
          }`}
        >
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 border-gray-200">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Recuperar contraseña
            </h3>

            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleCloseModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>

          <div className="p-4 md:p-5">
            <form className="space-y-4" onSubmit={handleRecoverPassword}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Correo electrónico
                </label>

                <input
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Ejemplo: usuario@ejemplo.com"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white transition-all duration-300"
                />
              </div>

              <button
                type="button"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-300"
                onClick={sendingData ? null : () => handleRecoverPassword()}
              >
                {sendingData ? <Loader /> : "Enviar"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordModal;