import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { resetPassword } from "../api/reset-password";
import { useToast } from "../context/ToastContext";
import Loader from "../components/Loader";

const ResetPassword = () => {
  const [sendingData, setSendingData] = useState(false);
  const [passwords, setPasswords] = useState({
    original: "",
    repeated: "",
  });
  const { showToast } = useToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const handleResetPassword = async () => {
    if (!passwords.original.trim() || !passwords.repeated.trim()) {
      showToast(
        "Debes introducir la contraseña y volver a repetirla.",
        "error",
        3000
      );
      return;
    }

    if (passwords.original !== passwords.repeated) {
      showToast("Las contraseñas no coinciden.", "error", 3000);
      return;
    }

    try {
      setSendingData(true);
      const response = await resetPassword({
        password: passwords.original,
        email,
        token,
      });

      showToast(response?.data?.message, "success", 3000);
    } catch (error) {
      showToast(error?.response?.data?.error || error.message, "error", 3000);
    } finally {
      setSendingData(false);
    }
  };

  return (
    <main className="flex items-center justify-center min-h-screen bg-gray-100 transition-all duration-300">
      <form className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Restablecer Contraseña
        </h2>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-gray-700 text-sm font-semibold mb-2"
          >
            Nueva Contraseña
          </label>
          <input
            id="password"
            type="password"
            value={passwords.original}
            onChange={(e) =>
              setPasswords((prev) => ({
                ...prev,
                original: e.target.value,
              }))
            }
            placeholder="Ingresa tu nueva contraseña"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition-all duration-300"
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="confirmPassword"
            className="block text-gray-700 text-sm font-semibold mb-2"
          >
            Repetir Contraseña
          </label>
          <input
            id="confirmPassword"
            type="password"
            value={passwords.repeated}
            onChange={(e) =>
              setPasswords((prev) => ({
                ...prev,
                repeated: e.target.value,
              }))
            }
            placeholder="Repite tu nueva contraseña"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition-all duration-300"
          />
        </div>
        <button
          type="button"
          onClick={sendingData ? null : () => handleResetPassword()}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded transition-colors duration-300"
        >
          {sendingData ? <Loader /> : "Restablecer Contraseña"}
        </button>
      </form>
    </main>
  );
};

export default ResetPassword;